<template>
  <div class="environment-container">
    <router-view/>
  </div>
</template>

<script>
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageEnvironment',
  breadcrumb () {
    const { projectCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, this.envCanonical, [
      {
        label: this.$t('routes.projectEnvironments'),
        name: 'projectEnvironments',
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  props: {
    envCanonical: {
      type: String,
      required: true,
    },
    projectCanonical: {
      type: String,
      required: true,
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.environment',
      },
      es: {
        title: '@:routes.environment',
      },
      fr: {
        title: '@:routes.environment',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .environment-container {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    align-content: stretch;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    margin-top: 0 !important;
  }

  ::v-deep {
    .v-expansion-panel {
      .new-header & {
        background-color: transparent;
      }
    }

    .v-item-group {
      .new-header & {
        background-color: transparent;
      }
    }
  }
</style>
